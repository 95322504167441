body {
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  font-family: Arial, sans-serif;
}

.App {
  text-align: center;
  padding: 20px;
  position: relative; /* Added for help button positioning */
}

.start-screen, .player-role-screen, .score-screen, .board-screen, .reveal-screen, .lobby-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 20px;
  background: #1c1c1c;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  margin: 20px;
  position: relative; /* Added for help button positioning */
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
  background: linear-gradient(90deg, #ff6f61, #d33fb0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

input[type="text"] {
  padding: 10px;
  font-size: 1.2em;
  border: 1px solid #333;
  border-radius: 8px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 300px;
}

button {
  margin: 10px;
  padding: 15px 30px;
  font-size: 1.2em;
  border-radius: 8px;
  background-color: #1e88e5;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s, transform 0.3s;
}

button:hover {
  background-color: #1565c0;
  transform: scale(1.05);
}

/* Help button positioning */
.help-button {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10; /* Ensure it's on top */
  background-color: #1e88e5;
  border: none;
  border-radius: 8px;
  color: #ffffff;
  padding: 8px 16px;
}

.help-button:hover {
  background-color: #1565c0;
}

.lobby-buttons, .score-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lobby-buttons input[type="text"] {
  margin-bottom: 10px;
}

.player-slider, .score-list, .board, .reveal-list {
  margin-top: 20px;
}

.player-slider label {
  font-size: 1.2em;
}

.player-slider input[type="range"] {
  width: 100%;
  max-width: 300px;
  margin-top: 10px;
}

.board {
  display: grid;
  gap: 10px;
  margin: 20px;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for desktop */
  grid-template-rows: repeat(3, 1fr); /* 3 rows for desktop */
  padding: 10px;
  background: #1c1c1c;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.board-cell {
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 1em;
  color: #ffffff;
  height: 60px;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  padding: 10px;
  border: 1px solid #444;
}

.board-cell:hover {
  background-color: #444;
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.score-screen {
  background: #1c1c1c;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.score-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #333;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px 0;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.score-item span {
  font-size: 1.2em;
}

.score-item button {
  margin: 0 5px;
  padding: 8px 16px;
  font-size: 1em;
  border-radius: 8px;
  background-color: #1e88e5;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s, transform 0.3s;
}

.score-item button:hover {
  background-color: #1565c0;
  transform: scale(1.05);
}

.hidden {
  display: none;
}

.lobby-code {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1.2em;
}

.lobby-code span {
  margin-right: 10px;
}

.lobby-code button {
  margin-left: 10px;
  padding: 8px 16px;
  font-size: 1em;
  border-radius: 8px;
  background-color: #1e88e5;
  border: none;
  cursor: pointer;
  color: #ffffff;
  transition: background-color 0.3s, transform 0.3s;
}

.lobby-code button:hover {
  background-color: #1565c0;
  transform: scale(1.05);
}

.player-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}

.player-item {
  background: #333;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 5px 0;
  width: 100%;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.player-item:hover {
  background-color: #444;
  transform: scale(1.02);
}

.player-item span {
  font-size: 1.2em;
}

.header-image {
  max-width: 80%;
  height: auto;
  margin-bottom: 20px;
}

/* Role reveal styling */
.role-reveal {
  font-size: 2em;
  color: #D3D3D3; /* Light grayish white */
  margin-bottom: 20px;
  text-align: center;
  background: none; /* No gradient background */
  -webkit-background-clip: border-box; /* Ensures the color is applied directly */
  -webkit-text-fill-color: #D3D3D3; /* Directly fills the text with the color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); /* Optional: Adds a subtle shadow for better visibility */
}

/* Mobile view adjustments */
@media (max-width: 600px) {
  .start-screen, .player-role-screen, .score-screen, .board-screen, .reveal-screen, .lobby-screen {
    padding: 15px;
    margin: 10px;
    height: auto;
    box-shadow: none;
  }

  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-size: 1.8em;
  }

  input[type="text"] {
    font-size: 1em;
    padding: 8px;
  }

  button {
    padding: 12px 24px;
    font-size: 1em;
  }

  .board {
    grid-template-columns: repeat(3, 1fr); /* 3 columns for smaller screens */
    grid-template-rows: repeat(4, 1fr); /* 4 rows for smaller screens */
  }

  .board-cell {
    font-size: 0.9em;
    height: 50px;
  }

  .player-slider input[type="range"] {
    width: 100%;
  }

  .score-item {
    padding: 8px 16px;
    margin: 5px 0;
    width: 90%;
  }

  .score-item span {
    font-size: 1em;
  }

  .score-item button {
    padding: 6px 12px;
    font-size: 0.9em;
  }

  .lobby-code {
    font-size: 1em;
    flex-direction: column;
  }

  .lobby-code button {
    margin: 10px 0 0 0;
  }

  .player-item {
    padding: 8px 16px;
  }

  .player-item span {
    font-size: 1em;
  }
}

/* Very small mobile view adjustments */
@media (max-width: 400px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  input[type="text"] {
    font-size: 0.9em;
    padding: 6px;
  }

  button {
    padding: 10px 20px;
    font-size: 0.9em;
  }

  .board-cell {
    font-size: 0.8em;
    height: 40px;
  }

  .score-item {
    padding: 6px 12px;
    margin: 5px 0;
    width: 90%;
  }

  .score-item span {
    font-size: 0.9em;
  }

  .score-item button {
    padding: 5px 10px;
    font-size: 0.8em;
  }

  .player-item {
    padding: 6px 12px;
  }

  .player-item span {
    font-size: 0.9em;
  }
}

